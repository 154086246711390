.store-type {
    color: var(--gray-900, #323232);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0px;
}

.cluster {
    :global(.rs-picker-toggle) {
        border: none;
        max-width: min-content;
        padding-left: 0px;
    }

    :global(.rs-picker-toggle-placeholder) {
        color: var(--gray-900);
    }

    &__render-loading {
        color: #8e8e93;
        cursor: default;
        padding: 6px 12px 12px;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
    }

    &__render-extra-footer {
        :global(.rs-divider) {
            margin: 0px;
        }

        button {
            background: none;
            outline: none;
            border: none;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        ul,
        li {
            margin: 0px;
            padding: 0px;
            list-style: none;
        }
    }

    :global(.rs-picker-toggle-value) {
        color: var(--gray-900, #323232) !important;
    }
}

.store {
    &__address {
        color: var(--gray-600, #959595);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    &__store-name {
        color: var(--gray-900, #323232);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}
